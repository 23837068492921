import React from "react";
import { Link  } from "react-router-dom";
import authAxios from "../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faFolder, faUser, faBriefcase, faUserGraduate, faNetworkWired, faHeadset, faCashRegister, faCogs, faChevronRight, faShieldAlt } from '@fortawesome/free-solid-svg-icons'

class Submenu extends React.Component {
  
  constructor(props) {
    super(props);
  }
  state = {
    SubModulos: [],
}
  Submodulos(id){

    authAxios.get("roles/submodulos/"+id)
        .then((datosRespuesta) => {
            console.log('Cargand ..'+id+'.'+datosRespuesta.data.data);
            
            let  datos2 = datosRespuesta.data.data;
           
            this.setState({ SubModulos: datosRespuesta.data.data });

        })
  
        .catch(console.log);
  }
  Recargar(id){
      console.log('paso'+id)
      
  }
  componentDidMount() {
    this.Submodulos(this.props.sub); 
  }
  render()  {
    const { SubModulos} = this.state;
    return (
      <div>
      {
        SubModulos.map((item) => (
          <li className="nav-item" key={item.id}>
          <Link to={'/pages/'+item.id} className="nav-link" onClick={()=>this.Recargar(item.id)}>
            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
            <p>{item.titulo}</p>
          </Link>
        </li>
        ))
      }
  </div>
    )
  }
}

export default Submenu;
