import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faCogs, faChevronRight, faTags, faFolder, faFolderOpen, faChartBar, faLayerGroup, faHandHoldingUsd, faCoins, faHandshake, faBookOpen } from '@fortawesome/free-solid-svg-icons'
import authAxios from "../services/Token";
import axios from "axios";
import Swal from 'sweetalert2';
import Submenu from './Submenu';
class Menu extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    dolarText: '',
    Modulos: [],
    SubModulos: [],
    names: [],
    NombreUsuario:window.localStorage.getItem('username'),
  }
  modulos() {
    authAxios.get("roles/modulos")
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        var status = datosRespuesta.data.status;
        if (status) {
          Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
          window.localStorage.clear();
          window.location.href = '/';
        }
        this.setState({ Modulos: datosRespuesta.data.data });
      })
      .catch(console.log);
  }
  dolarHoy() {
    let pesosESLocale = Intl.NumberFormat("es-ES");
    axios.get("https://s3.amazonaws.com/dolartoday/data.json")
      .then((datosRespuestaDolar) => {
        this.setState({ dolarText: '$ ' + pesosESLocale.format(datosRespuestaDolar.data.USDCOL.ratetrm)})

        window.localStorage.setItem('valorDolar', datosRespuestaDolar.data.USDCOL.ratetrm);
        this.setState({ NombreUsuario: window.localStorage.getItem('nombre')})
      })
  }
  componentDidMount() {
    //this.modulos();
    //this.dolarHoy();
  }
  render() {
    const { Modulos, SubModulos, NombreUsuario, dolarText } = this.state;
    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <Link to="/home" className="brand-link">
            <img src={process.env.PUBLIC_URL + '/dist/img/wms.png'} className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
            <span className="brand-text font-weight-light">WMS APP</span>
          </Link>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img src={process.env.PUBLIC_URL + '/dist/img/user2-160x160.jpg'} className="img-circle elevation-2" alt="User Image" />
              </div>
              <div style={{ color: "#C2C7D0" }} className="info">
                {NombreUsuario}
              </div>
            </div>
            
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              

                <li className="nav-item menu-close">
                  <a href="#" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15 }} icon={faBookOpen} />
                    <p>
                      Inventarios
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    
                    <li className="nav-item">
                      <Link to="/listaorden" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>1. Lista de Ordenes</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/listadoporteria" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>2. Ingreso por porteria</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/listaingresos" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>3. Lista de Entradas</p>
                      </Link>
                    </li>
                    
                   
                  </ul>
                </li>
                <li className="nav-item menu-close">
                  <a href="#" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15 }} icon={faUserSecret} />
                    <p>
                      Terceros
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                  <li className="nav-item">
                      <Link to="/MasterClientes" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Clientes</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterCompany" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Empresas</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterConductores" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Conductores </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterVehiculos" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Vehiculos </p>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li className="nav-item menu-close">
                  <a href="#" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15 }} icon={faCogs} />
                    <p>
                      Configuracion
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item menu-close">
                      <a href="#" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, fontSize: 10, marginLeft: 10 }} icon={faFolderOpen} />
                        <p>
                        Datos Maestros
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                       <li className="nav-item">
                          <Link to="/MasterCenter" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>1. Centros</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/MasterStore" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>2. Almacenes</p>
                          </Link>
                        </li>
                        
                        <li className="nav-item">
                          <Link to="/MasterArea" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>3. Areas</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/MasterSubarea" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>3.1. Subarea</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                      <Link to="/MasterPasillos" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Pasillos</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterColumnas" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Columnas</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterAlturas" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Alturas</p>
                      </Link>
                    </li>
                        <li className="nav-item">
                      <Link to="/MasterMapas" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Mapas</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterCategorias" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Categorias</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterSubCategorias" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Sub Categorias</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterMarcas" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Marcas</p>
                      </Link>
                    </li>
                    
                    <li className="nav-item">
                      <Link to="/MasterTipos" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Configuracion de Tipos</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterClasificacion" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Tipos de Clasificacion</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterCaracteristicas" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Tipos de Caracteristicas</p>
                      </Link>
                    </li>
                  
                    <li className="nav-item">
                      <Link to="/MasterProductos" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Productos</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/MasterParqueadero" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Parqueadero</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Expirationmargin" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Margen de expiracion</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Estibas" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Estibas</p>
                      </Link>
                    </li>
                      </ul>
                    </li>
                    <li className="nav-item menu-close">
                      <a href="#" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, fontSize: 10, marginLeft: 10 }} icon={faFolderOpen} />
                        <p>
                          Localizaciones
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                     
                        <li className="nav-item">
                      <Link to="/MasterPaises" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Paises</p>
                      </Link>
                       </li>
                       <li className="nav-item">
                          <Link to="/MasterRegion" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>Region</p>
                          </Link>
                        </li>
                       
                       <li className="nav-item">
                          <Link to="/MasterCity" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>Ciudades</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Localizaciones" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>Localizaciones</p>
                          </Link>
                        </li>
                       
                      </ul>
                    </li>
                    <li className="nav-item menu-close">
                      <a href="#" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, fontSize: 10, marginLeft: 10 }} icon={faFolderOpen} />
                        <p>
                          Cronograma Fechas
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                     
                        <li className="nav-item">
                      <Link to="/Daysweek" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Dias de la semana</p>
                      </Link>
                       </li>
                     
                       
                       <li className="nav-item">
                          <Link to="/festivos" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>Festivos</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/franjas" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>Configuracion de horas</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/franjas" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>Franjas Horaria </p>
                          </Link>
                        </li>
                       
                      </ul>
                    </li>
                    <li className="nav-item menu-close">
                      <a href="#" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, fontSize: 10, marginLeft: 10 }} icon={faFolderOpen} />
                        <p>
                          Procesos
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                     
                        <li className="nav-item">
                      <Link to="/entidades" className="nav-link">
                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                        <p>Entidades</p>
                      </Link>
                       </li>
                     
                       
                       <li className="nav-item">
                          <Link to="/procesos" className="nav-link">
                            <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                            <p>Procesos</p>
                          </Link>
                        </li>
                        
                       
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>
      </div>
    )
  }
}

export default Menu;