import React from 'react';
import Swal from 'sweetalert2';

import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faList, faTrashAlt, faCheck,faPlus,faPowerOff } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Api from "../../services/Api";


class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        Registros: [],
        Modulos: [],
        Permisos: [],
        Relacion:[],
        Relacion2:[]
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(Api+"checkin/order/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', 'Se ha borrado con exito', 'success')
                        this.Listar(1);
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };

    Listar(page) {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        axios.get(Api+"checkin/order?page="+page, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((datosRespuesta) => {
                Swal.close();
               
                console.log(datosRespuesta.data);
                this.setState({ datosCargados: true, load: '', Registros: datosRespuesta.data.items, pagina: datosRespuesta.data.total, ultimo: datosRespuesta.data.last_page, actual: datosRespuesta.data.page });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    pasar_item(id) {
        window.localStorage.setItem("orden", id);
    }

    componentDidMount() {
        this.Listar(1);
       
      
    }
    render() {
        const { load, Registros, pagina, ultimo, actual } = this.state;
        return (
            <div className="content">
                    <div className="content-wrapper">
            <div className="content">
              
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                        <Link
                                                                className="btn btn-success btn-sm"
                                                                to={"/ordencompra"}
                                                                onClick={() =>
                                                                    this.pasar_item(0)
                                                                }
                                                            >
                                                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faPlus} />
                                                                Nueva Orden de Compra
                                                            </Link>
                         
                        </div>
                        <div className="card-body">
                        <h4> Ordenes de Compra</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="Buscar" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.Listar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                        <th className="align-middle">CONSECUTIVO</th>
                                            <th className="align-middle">ORDEN COMPRA</th>
              
                                            <th className="align-middle">DESCRIPCION</th>
                                            <th className="align-middle">FECHA DE REGISTRO</th>
                                            <th className="align-middle">FECHA DE LLEGADA</th>
                                            <th className="align-middle">ESTADO</th>
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            Registros.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.number}</td>
                                                    <td>{item.observation}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.duedate}</td>
                                                    <td>{item.aprrove==true?<FontAwesomeIcon style={{ color: 'green' }} icon={faCheck} />:<FontAwesomeIcon style={{ color: 'red' }} icon={faPowerOff} />}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                        {item.aprrove==false?
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() =>
                                                                    this.borrarRegistros(item.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>
                                                            :''}
                                                            {/* <button
                                                                type="button"
                                                                className="btn btn-info btn-sm"
                                                                data-bs-toggle='modal' data-bs-target="#permisoRolesModal"
                                                                onClick={() =>
                                                                    this.pasar(item.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faList} />
                                                            </button> */}
                                                            &nbsp;&nbsp;&nbsp;
                                                            <Link
                                                                className="btn btn-primary btn-sm"
                                                                to={"/ordencompra"}
                                                                onClick={() =>
                                                                    this.pasar_item(item.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faList} />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        );
    }
}

export default Listar;