import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Api from "../../services/Api";


class Porteria extends Component  {
   
    state = {
        load: '',
        datosCargados: false,
        Registros: [],
        Modulos: [],
        Relacion: []
    }
 
    Listar(page) {
        Swal.showLoading();
       
        axios.get(Api+"checkin/income?page="+page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();
                 var dato = datosRespuesta.data;
                console.log(datosRespuesta.data);
                this.setState({ datosCargados: true, load: '', Registros: dato.items, pagina: dato.total, ultimo: dato.last_page, actual: dato.page });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }

    pasar = (id) => {
        console.log(id)
        //document.getElementById("orden").value = id;
        window.localStorage.setItem("orden",id);
        this.props.llamarFuncion(id);
        
    }
    componentDidMount() {
      this.Listar(1);
    }
    render() {
        const { load, Registros, pagina, ultimo, actual, Modulos, Relacion } = this.state;
        return (
            <div>
                 <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                        <th className="align-middle">INGRESO</th>
                                        <th>DESCRIPCION</th>
                                            <th className="align-middle">ORDEN COMPRA</th>
                                            <th className="align-middle">FECHA LLEGADA</th>
                                            
                                            <th className="align-middle">SELECCIONAR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            Registros.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.observation}</td>
                                                    <td>{item.order}</td>
                                                    <td>{item.date}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                           
                                                          
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm"
                                                                data-bs-dismiss="modal"
                                                                onClick={() =>
                                                                    this.pasar(item.order)
                                                                }
                                                            >
                                                               <i class="fas fa-check"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
            </div>
        );
    }
}

export default Porteria;