import React from 'react';
import Swal from 'sweetalert2';

import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faCheck,faPowerOff } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Api from "../../services/Api";


class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        Registros: [],
        Modulos: [],
        Permisos: []
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(Api+"parameters/parking/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', 'Se ha borrado con exito', 'success')
                        this.Listar(1);
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };

    Listar(page) {
        
        let bname = document.getElementById('bnombre').value;
        var data = {search:bname}
        axios.get(Api+"parameters/parking?page="+page,data, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((datosRespuesta) => {
              
                console.log(datosRespuesta.data);
                this.setState({ datosCargados: true, load: '', Registros: datosRespuesta.data.items, pagina: datosRespuesta.data.total, ultimo: datosRespuesta.data.last_page, actual: datosRespuesta.data.page });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }

    pasar = (id) => {

        axios.get(Api+"parameters/parking/"+id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
              
                this.setState({ Modulos: datosRespuesta.data.data.item });
                var fila = datosRespuesta.data.data.item;
                document.getElementById("id").value= fila.id;
                document.getElementById("reference").value= fila.reference;
                document.getElementById("number").value=fila.number;
                
               

            })
            .catch(console.log);
    }
    Nuevo = () => {
        this.setState({ Modulos: '' });
        document.getElementById("form_roles").reset();

    }

    save(sw) {
        var datos = new FormData(document.getElementById("form_roles"));
        console.log(datos);
        axios.post(Api+"parameters/parking", datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                this.Listar(1);
                this.Nuevo();
                document.getElementById("form_roles").reset();
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }

                if(sw==2){
                    const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
                    closeButton.click();
                }
            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  //const  da = error.response.message;
                   
                  if(error.response.data.message['website']){
                    Swal.fire("Mensaje", "Debes de escribir un url valida", "error");
                    return false;
                  }
                  if(error.response.data.message['email']){
                    Swal.fire("Mensaje", "Debes de escribir un correo valido", "error");
                    return false;
                  }
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Erro al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
    }
    update() {
        var datos = new FormData(document.getElementById("form_roles"));
        console.log(datos);
        var id = document.getElementById("id").value;
        axios.patch(Api+"parameters/parking/"+id, datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                this.Listar(1);
                this.Nuevo();
                document.getElementById("form_roles").reset();
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se actualizo con exito',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'No hubo ningun cambio',
                        'error'
                    )
                }
                const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
                closeButton.click();
            })
            .catch(console.log)
    }
    componentDidMount() {
        this.Listar(1);
    }
    render() {
        const { load, Registros, pagina, ultimo, actual, Modulos, Permisos } = this.state;
        return (
            <div className="content">
                <div className="modal fade" id="permisoRolesModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Formulario de Registro</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <form id="form_roles">
                                                <div className="row g-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="id_modulo">Referencia</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="reference" id="reference" placeholder=""  />
                                               
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label htmlFor="id_modulo">Numero</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="number" id="number" placeholder=""  />
                                                        <input className={"form-control form-control-sm"} type="hidden" name="id" id="id" placeholder=""   />
                                                        <input className={"form-control form-control-sm"} type="hidden" name="account" id="account" value="1" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                      
                                        
                                    </div>
                                    
                                    
                                    <div className="modal-footer">
                                        {!Modulos.id?
                                        <><button type="button" className="btn btn-primary btn-sm" onClick={() => this.save(1)}>Guardar y Agregar otro</button><button type="button" className="btn btn-primary btn-sm"  onClick={() => this.save(2)}>Guardar</button></>:
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => this.update()}>Editar</button>
                                        }
                                        
                                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                                <hr />
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                            <button
                                type="button"
                                className="btn btn-success btn-sm"
                                data-bs-toggle='modal' data-bs-target="#permisoRolesModal"
                                onClick={() => this.Nuevo()}
                            >
                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faPlus} />
                                &nbsp;&nbsp;Nuevo Registro
                            </button>
                        </div>
                        <div className="card-body">
                            <h4> Lista de parqueadero</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="Buscar" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.Listar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                        <th className="align-middle">REFERENCIA</th>
                                            <th className="align-middle">NUMERO</th>
              
                                            
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            Registros.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.reference}</td>
                                                    <td>{item.number}</td>
                                                  
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                           
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() =>
                                                                    this.borrarRegistros(item.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>&nbsp;&nbsp;&nbsp;
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm"
                                                                data-bs-toggle='modal' data-bs-target="#permisoRolesModal"
                                                                onClick={() =>
                                                                    this.pasar(item.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faEdit} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;