import React, { Component } from 'react';
import Swal from 'sweetalert2';

import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faCheck, faPowerOff, faPrint } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Api from "../../services/Api";
import ModalCliente from '../Modales/Clientes';
import ModalProveedor from '../Modales/Proveedor';
import ModalProductos from '../Modales/Productos';
import ModalUsuarios from '../Modales/Usuarios';
import ModalAlmacen from '../Modales/Almacenes';
import ModalOrden from '../Modales/OrdenCompra';
import ModalPorteria from '../Modales/Porteria';
import Localizacion from '../Modales/Maps';
import ModalOrdenDetalle from '../Modales/OrdenCompraDetalle';
import { PDFViewer } from '@react-pdf/renderer';
import InvoicePDF from './Pdf';

class Listar extends Component {
    
    state = {
        load: '',
        datosCargados: false,
        Registros: [],
        RegistrosOrden: [],
        Modulos: [],
        Relacion: [],
        Categoria:[],
        Unidades:[],
        Buscador:'0',
        Totales:0,
        Emegergente: '',
        Aprobado:false,
        Disabled:false,
        IdOrden: window.localStorage.getItem("orden"),
        Encabezado:[],
        DatosCli:[],
        DatosPro:[],
        DatosCen:[],
        IdOrdenIng:window.localStorage.getItem("orden_ing"),
    }
    miFuncion = (dato) => {
        // Lógica de la función que recibe el dato
        this.setState({ IdOrden: dato});
        this.VerRegistro();
        //this.Listar();
      }
    VerRegistro = () => {
        const { IdOrden} = this.state;
        var ord = window.localStorage.getItem("orden");
        //document.getElementById("number_ing").value= ord;
        axios.get(Api+"checkin/order/"+ord)
            .then((datosRespuesta) => {
                //console.log(datosRespuesta);
              
                //this.setState({ Modulos: datosRespuesta.data.data.item });
                var fila = datosRespuesta.data.data.item;
                document.getElementById("id").value= fila.id;
                document.getElementById("order").value= fila.id;
                document.getElementById("number").value= fila.number;
                document.getElementById("observation").value=fila.observation;
                //document.getElementById("aprrove").checked=fila.aprrove;
                document.getElementById("date").value=fila.date;
                document.getElementById("duedate").value=fila.duedate;
                document.getElementById("value").value=fila.value;
                document.getElementById("user").value=fila.user;
                document.getElementById("customer").value=fila.customer;
                document.getElementById("proovedor").value=fila.proovedor;
                document.getElementById("account").value=fila.account;
                document.getElementById("almacen").value=fila.almacen;
               
                this.setState({ Aprobado: fila.aprrove, Encabezado:fila });
                this.Cliente(fila.customer);
                this.Proveedor(fila.proovedor);
                this.Usuarios(fila.user);
                this.Almacen(fila.almacen);
            })
            .catch(console.log);
    }
    VerRegistroIngreso = () => {
        const { IdOrden} = this.state;
        var ord = window.localStorage.getItem("orden_ing");
        //document.getElementById("number_ing").value= ord;
        axios.get(Api+"checkin/ingress/"+ord)
            .then((datosRespuesta) => {
                //console.log(datosRespuesta);
              
                //this.setState({ Modulos: datosRespuesta.data.data.item });
                var fila = datosRespuesta.data.data.item;
                document.getElementById("id_ing").value= fila.id;
                document.getElementById("number_ing").value= fila.number;
                document.getElementById("observation_ing").value=fila.observation;
                document.getElementById("date_ing").value=fila.date;
                document.getElementById("order").value=fila.order;
                this.ListarOrden(fila.order);
                 this.VerRegistro();
            })
            .catch(console.log);
    }
    Proveedor = (id) => {

        axios.get(Api+"parameters/customer/"+id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                var fila = datosRespuesta.data.data.item;
                document.getElementById("proovedor_name").value= fila.name;
                document.getElementById("proovedor_doc").value= fila.nit;
                this.setState({ DatosPro: fila });
            })
            .catch(console.log);
    }
    Cliente = (id) => {

        axios.get(Api+"parameters/customer/"+id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                var fila = datosRespuesta.data.data.item;
                this.setState({ DatosCli: fila });
                document.getElementById("customer_name").value= fila.name;
                document.getElementById("customer_doc").value= fila.nit;
            })
            .catch(console.log);
    }
    Almacen = (id) => {

        axios.get(Api+"configuration/center/"+id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                var fila = datosRespuesta.data.data.item;

                document.getElementById("almacen_name").value = fila.name;
                document.getElementById("almacen_code").value = fila.code;
                document.getElementById("almacen_dir").value = fila.address;
                document.getElementById("almacen_tel").value = fila.phone;
                this.setState({ DatosCen: fila });
            })
            .catch(console.log);
    }
    Usuarios = (id) => {

        axios.get(Api+"users/user/"+id)
            .then((datosRespuesta) => {
                console.log("datos del usuario");
                console.log(datosRespuesta.data.data);
                var fi = datosRespuesta.data.data.item;
                document.getElementById("user_name").value= fi.email;
            })
            .catch(console.log);
    }
    Buscar(option){
        this.setState({ Buscador: option});
        let componentToRender;
        const {Unidades}= this.state;

        if (option === 'Cliente') {
        componentToRender = <ModalCliente />;
        } 
        if (option === 'Proveedor') {
        componentToRender = <ModalProveedor />;
        } 
        if (option === 'Productos') {
            componentToRender = <ModalProductos />;
            } 
        if (option === 'Usuario') {
                componentToRender = <ModalUsuarios />;
        } 
        if (option === 'Localizacion') {
            componentToRender = <Localizacion />;
    } 
        if (option === 'Almacen') {
            componentToRender = <ModalAlmacen />;
        } 
        if (option === 'Orden') {
            componentToRender = <ModalOrden llamarFuncion={this.miFuncion}/>;
         } 
         if (option === 'Porteria') {
            componentToRender = <ModalPorteria llamarFuncion={this.miFuncion}/>;
         } 
         if (option === 'OrdenDetalle') {
            componentToRender = <ModalOrdenDetalle  Unidades={Unidades}/>;
         } 
        this.setState({ Emegergente: componentToRender});
    }
    pasarId(id){
        alert(id);
    }
    save() {
        var datos = new FormData(document.getElementById("formulario"));
        console.log(datos);
        axios.post(Api+"checkin/ingress", datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta.data.data)
                //this.Listar(1);
                //this.Nuevo();
                this.setState({ IdOrden: datosRespuesta.data.data.Item.id});
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se guardo con exito la orden',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'Se guardo con exito la orden',
                        'error'
                    )
                }

               
            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Erro al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
    }
    editar() {
        const { IdOrden} = this.state;
        var datos = new FormData(document.getElementById("formulario"));
        console.log(datos);
        axios.patch(Api+"checkin/order/"+IdOrden, datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta.data.data)
                //this.Listar(1);
                //this.Nuevo();
                //this.setState({ IdOrden: datosRespuesta.data.data.Item.id});
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se edito con exito la orden',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'Se edito con exito la orden',
                        'error'
                    )
                }

               
            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Erro al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(Api+"checkin/ingressdetail/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', 'Se ha borrado con exito', 'success')
                        this.Listar();
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    save_item(id) {
        const { IdOrdenIng} = this.state;
        var order = document.getElementById("order").value;
        var item = document.getElementById("item"+id).value;
        var observation = document.getElementById("observation_item"+id).value;
        var amount = document.getElementById("amount"+id).value;
        var cantidad = document.getElementById("cantidad"+id).value;
        if(parseInt(amount)>parseInt(cantidad)){
            Swal.fire(
                'Registro!',
                'La cantidad a recibir es mayor a la solicitada',
                'error'
            )
            return false;
        }
        var location = '';
        var datos = {ingress:IdOrdenIng,observation:observation,item:item,location:location,amount:amount};
        console.log(datos);
        var item_id = document.getElementById("id"+id).value;

        if(item_id==''){
            axios.post(Api+"checkin/ingressdetail", datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta.data.data)
                //this.Listar(1);
                //this.Nuevo();
                this.Listar();
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se agrego con exito',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'Se agrego con exito el items',
                        'error'
                    )
                }
                
               
            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Erro al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
        }else{
            axios.patch(Api+"checkin/ingressdetail/"+item_id, datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta.data.data)
                //this.Listar(1);
                //this.Nuevo();
                this.Listar();
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se edito con exito',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'Se edito con exito el items',
                        'error'
                    )
                }

               
            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Erro al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
        }
        
    }
    Nuevo_item = () => {
        document.getElementById("item0").value = '';
        document.getElementById("item_name0").value = '';
        document.getElementById("item_code0").value = '';
        document.getElementById("item_name0").focus();
        document.getElementById("observation_item0").value = '';
        document.getElementById("location0").value = '';
        document.getElementById("amount0").value = '';
        document.getElementById("location_name0").value = '';
    }
  
    Listar() {
        const { IdOrdenIng} = this.state;
       
        axios.get(Api+"checkin/ingressdetail?order="+IdOrdenIng, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((datosRespuesta) => {
                console.log('datos de ingreso');
                console.log(datosRespuesta.data);
                this.setState({ Registros: datosRespuesta.data.items });
                this. ListarOrden();
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarOrden(ord) {
        

        axios.get(Api+"checkin/orderdetail?id_order="+ord, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
             
                 var datox = datosRespuesta.data;
                console.log(datosRespuesta.data);
                this.setState({ RegistrosOrden: datox.items});
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    Listarcategoria(page) {

        axios.get(Api+"parameters/category?page="+page, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((datosRespuesta) => {
                Swal.close();
               
                console.log(datosRespuesta.data);
                this.setState({ Categoria: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarUnidad(page) {

        axios.get(Api+"unitmeasurement?page="+page, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((datosRespuesta) => {
                Swal.close();
               
                console.log(datosRespuesta.data);
                this.setState({ Unidades: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    BuscarProducto() {
     
       var buscar = document.getElementById("item_code0").value;
        axios.get(Api+"configuration/item?search="+buscar, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
               
                 var dato = datosRespuesta.data;
                console.log(datosRespuesta.data);
                //this.setState({ datosCargados: true, load: '', Registros: dato.items, pagina: dato.total, ultimo: dato.last_page, actual: dato.page });
                document.getElementById("item0").value = dato.items[0].id;
                document.getElementById("item_name0").value = dato.items[0].description;
                //document.getElementById("item_code0").value = dato.items[0].code;
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    componentDidMount() {
        this.Listarcategoria(1);
        this.ListarUnidad(1);
        this.VerRegistroIngreso();
        this.Listar();
        
    }
    render() {
        const { RegistrosOrden,IdOrdenIng,load, Registros, pagina, ultimo, actual,DatosCli,DatosPro,DatosCen,Encabezado, IdOrden, Buscador,Emegergente,Aprobado,Disabled,Categoria,Unidades,Totales } = this.state;
        return (
            <div className="content">
                <div className="modal fade" id="ModalGlobal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Buscar {Buscador}</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            
                                            {Emegergente}
                                        </div>


                                    </div>


                                    <div className="modal-footer">
                                      
                                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                                <hr />

                            </div>
                        </div>
                    </div>

                </div>
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                            Radicado Entrada {IdOrdenIng} 
                        </div>
                       
                            <div className="card-body">
                               

                                <div className="row">
                                    <form id="formulario">
                             
                                        <div className="row g-3">
                                        <div className="col-md-3">
                                                <label htmlFor="id_modulo">Buscar Orden de Compra</label>
                                                <div class="input-group input-group-sm">
                                                <input className={"form-control form-control-sm"} type="hidden" name="id" id="id" placeholder="" />
                                                <input className={"form-control form-control-sm"} type="hidden" name="account" id="account" value="1" />
                                                <input className={"form-control form-control-sm"} type="text" disabled name="number" id="number" placeholder="" />
                                                <span class="input-group-append">
                                                        <button type="button" class="btn btn-info btn-flat" disabled={Disabled} data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Porteria')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                    </div>
                                            </div>
                                            <hr></hr>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Radicado Orden de Compra</label>
                                                
                                                 
                                                    <input type="text" class="form-control form-control-sm" readonly id='order' name='order'/>
                                                    
                                            </div>
                                        <div className="col-md-2">
                                                <label htmlFor="id_modulo">Numero</label>
                                                
                                                    <input type="hidden" class="form-control form-control-sm" id='id_ing' name='id_ing' value={IdOrdenIng}/>
                                                    <input type="text" class="form-control form-control-sm" id='number_ing' name='number'/>
                                                    
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor="id_modulo">Fecha de Registro</label>

                                                <input className={"form-control form-control-sm"} type="date" name="date" id="date_ing" placeholder="" />
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="id_modulo">Observaciones de ingreso</label>

                                                <input className={"form-control form-control-sm"} type="text" name="observation" id="observation_ing" placeholder="" />
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor="id_modulo">Generar Documento</label><br></br>
                                               
                                                  <button type='button' id='boton' className='btn btn-success btn-sm' onClick={() =>this.save()} ><i class="fas fa-plus"></i> Generar Ingreso</button>
                                                
                                                          <button
                                                                type="button"
                                                                className="btn btn-info btn-sm"
                                                                data-bs-toggle='modal' data-bs-target="#modalImprimir"
                                                                
                                                            >
                                                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faPrint} />
                                                            </button>
                                            </div>
                                            <hr/>
                                            
                                            <div className="col-md-2">
                                                <label htmlFor="id_modulo">No. Documento</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm" id='customer' name='customer' />
                                                    <input type="text" class="form-control form-control-sm" id='customer_doc' name='customer_doc' disabled/>
                                                    <span class="input-group-append">
                                                        <button type="button" class="btn btn-info btn-flat" disabled data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Cliente')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Cliente</label>

                                                <input className={"form-control form-control-sm"} type="text" disabled name="customer_name" id="customer_name" placeholder="" />
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor="id_modulo">Documento</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm"  id='proovedor' name='proovedor'/>
                                                    <input type="text" class="form-control form-control-sm" disabled  id='proovedor_doc' name='proovedor_doc'/>
                                                    <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat"  disabled data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Proveedor')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Nombre Proveedor</label>

                                                <input className={"form-control form-control-sm"} type="text" disabled name="proovedor_name" id="proovedor_name" placeholder="" />
                                            </div>
                                            

                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Fecha de Registro</label>

                                                <input className={"form-control form-control-sm"} type="date" disabled name="date" id="date" placeholder="" />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Fecha de Llegada</label>

                                                <input className={"form-control form-control-sm"} type="date" disabled name="duedate" id="duedate" placeholder="" />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Categoria</label>

                                                <select className="form-select form-control-sm" id="category"  name="category" disabled>
                                                                <option value={""}>Seleccione</option>
                                                                {
                                                                Categoria.map((item) => (
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Usuario</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm"  id='user' name='user'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='user_name' name='user_name'/>
                                                    <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat" disabled data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Usuario')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-2">
                                                <label htmlFor="id_modulo">Codigo Centro</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm" id='almacen' name='almacen'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='almacen_code' name='almacen_code'/>
                                                    <span class="input-group-append">
                                                        <button type="button" class="btn btn-info btn-flat" disabled data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Almacen')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="id_modulo">Nombre del centro</label>

                                                <input className={"form-control form-control-sm"} type="text" disabled name="almacen_name" id="almacen_name" placeholder="" />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Direccion del centro</label>

                                                <input className={"form-control form-control-sm"} type="text" disabled name="almacen_dir" id="almacen_dir" placeholder="" />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Telefono del centro</label>

                                                <input className={"form-control form-control-sm"} type="text" disabled name="almacen_tel" id="almacen_tel" placeholder="" />
                                            </div>


                                            <div className="col-md-9">
                                                <label htmlFor="id_modulo">Descripcion</label>
                                                <textarea className={"form-control form-control-sm"} name="observation" disabled id="observation" placeholder="" ></textarea>
                                            </div>
                                            



                                        </div>
                                    </form>
                                </div>
                            </div>
                       
                        <div className="card-footer text-muted">
                            <table className="table table-hover table-condensed table-sm">
                                <thead>
                                    <th style={{width:'100px'}}>Codigo</th>
                                    <th style={{width:'250px'}}>Descripcion del Producto</th>
                                    <th>Observaciones</th>
                                    <th style={{width:'70px'}}>Cantidad</th>
                                    <th>Recibir</th>
                                    <th>Item</th>
                                    <th>Opciones</th>
                                </thead>
                                <tbody>
                                  {/*   <tr>
                              

                                        <td>
                                            <div class="input-group input-group-sm">
                                               
                                                <input type="hidden"  id="item0" name='item' class="form-control form-control-sm" />
                                                <input type="text"  id="item_code0" name='item_code' class="form-control form-control-sm" onChange={() => this.BuscarProducto()}/>
                                                <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat" data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('OrdenDetalle')}><i class="fas fa-search"></i></button>
                                                </span>
                                            </div>
                                        </td>
                                        <td> <input type="text" id="item_name0" name='item_name0' className='form-control form-control-sm' /> </td>
                                        <td> <input type="text" id="observation_item0" name='observation' className='form-control form-control-sm' /> </td>
                                       
                                        <td> <input type="number" id="amount0" name='amount' className='form-control form-control-sm' /> </td>
                                        <td>
                                        <div class="input-group input-group-sm">
                                               
                                                <input type="hidden"  id="location0" name='location0' class="form-control form-control-sm" />
                                                <input type="text"  id="location_name0" name='location_name0' class="form-control form-control-sm" onChange={() => this.BuscarProducto()}/>
                                                <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat" data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Localizacion')}><i class="fas fa-search"></i></button>
                                                </span>
                                            </div>
                                        </td>
                                       
                                        <td> <button type='button' className='btn btn-success btn-sm' disabled={Disabled}  onClick={() =>this.save_item(0)} >[+]</button> </td>
                                        
                                        </tr> */}
                                        {
                                            RegistrosOrden.map((item) => (
                                                
                                                <tr key={item.id}>
                                                    
                                                    <td>
                                                        <input type="hidden" id={'item'+item.id} name='amount' defaultValue={item.id} className='form-control form-control-sm'  disabled={Disabled}/>
                                                        <input type="text" id={'item_code'+item.id} name='amount' defaultValue={item.item.code} className='form-control form-control-sm'  disabled={Disabled}/></td>
                                                    <td><input type="text" id={'item_name'+item.id} name='amount' defaultValue={item.item.description} className='form-control form-control-sm'  disabled={Disabled}/></td>
                                                    <td><input type="text" id={'observation_item'+item.id} name='amount' defaultValue={item.observation} className='form-control form-control-sm'  disabled={Disabled} /></td>
                                                    <td><input type="number" id={'cantidad'+item.id} name='amount' defaultValue={item.amount} className='form-control form-control-sm'  disabled={Disabled}/></td>
                                                    
                                                    <td>
                                                  
                                                        <input type="number" id={'amount'+item.id} name='location' defaultValue={Registros.filter(it => it.item === item.id).map(it => it.amount) }  className='form-control form-control-sm'  disabled={Disabled}/>
                                                        </td>
                                                        <td>
                                                        <input type="number" id={'id'+item.id} name='location' defaultValue={Registros.filter(it => it.item === item.id).map(it => it.id) }  className='form-control form-control-sm'  disabled={Disabled}/></td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                           
                                                            {/* <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() =>
                                                                    this.borrarRegistros(item.id)
                                                                }
                                                                disabled={Disabled}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>&nbsp;&nbsp;&nbsp; */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm"
                                                                
                                                                onClick={() =>
                                                                    this.save_item(item.id)
                                                                }
                                                                disabled={Disabled}
                                                            >
                                                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faPlus} />
                                                            </button>
                                                           
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    
                                </tbody>
                                <tr>
                                        <td colSpan={4}>Totales {Totales}</td>
                                        <td> <input className={"form-control form-control-sm"} type="text" name="value" id="value" value={Totales} placeholder="" /></td>
                                        <td></td>
                                    </tr>
                            </table>
                        </div>
                    </div>
                    
                </div>
                <div className="modal fade" id="modalImprimir" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle de la entrada</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="row">
                                   
                                    </div>     
                                    </div>
                                    <div className="modal-footer">
                                      
                                        
                                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                                <hr />
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Listar;